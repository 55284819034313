<template>
  <!-- <v-list-item
    v-if="folder.type === 'filter'"
    :class="[itemTheme, { [activeItemTheme]: folder.id === activeItemId }]"
  >
    <v-list-item-content
      :class="[itemTheme, { [activeItemTheme]: folder.id === activeItemId }]"
      @click="handleClick(folder)"
    >
      <v-list-item-title class="pa-0" v-if="!sideBarHidden">
        <v-icon class="mr-1 pl-1">{{ icon(folder.type) }}</v-icon>
        {{ folder.name }}
      </v-list-item-title>
      <v-list-item-title v-else>
        <v-icon class="mr-1 pl-1">{{ icon(folder.type) }}</v-icon>
      </v-list-item-title>
    </v-list-item-content>


    <div v-for="game in events" :key="game.id">
      <div class="io-game">
        <div class="io-game-title">
          {{
            game.title
              ? game.title.toUpperCase()
              : `${game.home_short_name} - ${game.away_short_name}`
          }}
        </div>
        <div
          v-for="clip in game.clips"
          class="io-game-clip"
          :class="{ chosen: isChosen(clip) }"
          :key="clip.id + 'gamejou'"
          @click="$emit('openEvent', { ...clip, video: game })"
        >
          <v-icon style="float: left; padding-top: 2px" class="pl-4" size="18"
            >mdi-play-circle-outline</v-icon
          >
          {{ clip.title }}
        </div>
      </div>
    </div>


  </v-list-item> -->

  <v-list-item
    @click="handleClick(folder)"
    :class="[itemTheme, { [activeItemTheme]: folder.id === activeItemId }]"
  >
    <v-list-item-icon class="pr-3">
      <v-icon>{{ icon(folder.type) }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title class="pa-0" v-show="!sideBarHidden || $vuetify.breakpoint.mobile">
      <!-- <v-icon v-if="hasGraphics">mdi-draw</v-icon> -->
      {{ folder.name }}
    </v-list-item-title>
    <!-- <v-list-item-title v-else>
      <v-icon class="mr-1 pl-1">{{ icon(folder.type) }}</v-icon>
    </v-list-item-title> -->
  </v-list-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["folder"],
  name: "SideBarItem",
  created() {
    
  },
  methods: {
    ...mapActions("presentation", ["handleInstance"]),

    handleClick(folder) {
      this.handleInstance({ folder });
    },

    /**
     * Returns the icon that the sideBarItem uses
     *
     * @param {string} type the type of the folder
     * @returns The icon to be appended before folder name in the sideBarItem
     */
    icon(type) {
      switch (type) {
        case "clip":
          return "mdi-video-outline";
        case "time":
          return "mdi-clock-outline";
        case "filter":
          return "mdi-filter-outline";
        case "map":
          return "mdi-dots-hexagon";
        case "text_file":
          return "mdi-file-document-outline";
        default:
          return "mdi-folder";
      }
    },
    hasGraphics(folder) {
      if (folder) return true;
      return false;
    }
  },
  computed: {
    ...mapGetters("presentation", ["activeItemId", "sideBarHidden"]),
    ...mapGetters("filter", ["events"]),
    ...mapGetters("draw", ["layers"]),

    itemTheme() {
      return this.$vuetify.theme.dark ? "item-dark" : "item-light";
    },

    activeItemTheme() {
      return this.$vuetify.theme.dark
        ? "active-item-dark"
        : "active-item-light";
    },
  },
};
</script>

<style>
.item-light:hover {
  background-color: #ddd;
  cursor: pointer;
}
.item-dark:hover {
  background-color: #3d3d3d;
  cursor: pointer;
}

.active-item-light {
  background-color: #ddd;
}
.active-item-dark {
  background-color: #3d3d3d;
}
</style>
